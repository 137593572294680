<template>
  <template-card
    :name="name"
    preview-width="140px"
    preview-height="20px"
    icon="$paper_num"
    @dragend:item="addItemDrop()"
    @click:item="addItem()" />
</template>
<script>
import paperBuilderToolMixin from '@/mixins/paperBuilderToolMixin'
import { v4 as uuid } from 'uuid'
import TemplateCard from './TemplateCard.vue'

export default {
  components: { TemplateCard },
  mixins: [
    paperBuilderToolMixin
  ],
  methods: {
    addItem () {
      const { left, top } = this.paperBuilderDrop || { left: '20px', top: '20px' }
      this.addPaperBuilderCanvas({
        id: this.paperBuilderId,
        data: {
          id: uuid(),
          type: 'number',
          data: 'order.invoice_number',
          tag: 'div',
          fx: {},
          properties: {
            style: {
              position: 'absolute',
              left,
              top,
              width: '140px',
              height: '20px',
              fontSize: '14px',
              overflow: 'hidden'
            }
          },
          children: []
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
